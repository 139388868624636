::-webkit-file-upload-button {
  background: none;
  border: none;
  color: rgba(17, 102, 238, 1);
  font-size: 18px;
}

progress {
  width: 100%;
  border-radius: 40px;
  height: 8px;
}

/* Chrome & Safari: Unfilled portion of the progress bar */
progress::-webkit-progress-bar {
  border-radius: 40px;
  background-color: rgba(232, 234, 244, 1);
  border-radius: 40px;
}

progress::-webkit-progress-value {
  border-radius: 40px;
  background-color: rgba(27, 45, 147, 1);
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: rgba(27, 45, 147, 1);
  --_m:
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}

@media only screen and (min-width: 1400px) {
  .full-container {
    margin: 0 15%;
  }
  #download-file {
    width: 340px;
  }
  #select-company {
    width: 340px;
  }
}

@media only screen and (min-width: 1101px) and (max-width: 1299px) {
  .full-container {
    margin: 0 8%;
  }
  #download-file {
    width: 260px;
  }
  #select-company {
    width: 260px;
  }
}

@media only screen and (min-width: 1300px) {
  .full-container {
    margin: 0 10%;
  }
  #download-file {
    width: 340px;
  }
  #select-company {
    width: 340px;
  }
}

@media only screen and (max-width: 1100px) {
  .full-container {
    margin: 0 5%;
  }
  #download-file {
    width: 220px;
  }
  #select-company {
    width: 220px;
  }
}